
export const troubleshoot = {
    vehicle_check: {
        url: 'troubleshoot/vehicle',
        method: 'GET',
        id: null,
        params: null
    },
    vehicle_fix: {
        url: 'troubleshoot/fix_vehicle',
        method: 'GET',
        id: null,
        params: null
    },
    driver_location_check: {
        url: 'troubleshoot/driver_location_check',
        method: 'GET',
        id: null,
        params: null
    },
    driver_location_fix: {
        url: 'troubleshoot/driver_location_fix',
        method: 'GET',
        id: null,
        params: null
    },
    driver_old_location_check: {
        url: 'troubleshoot/driver_old_location_check',
        method: 'GET',
        id: null,
        params: null
    },
    driver_old_location_fix: {
        url: 'troubleshoot/driver_old_location_fix',
        method: 'GET',
        id: null,
        params: null
    },
    campaigns_expired_issues_check: {
        url: 'troubleshoot/campaigns_expired_issues_check',
        method: 'GET',
        id: null,
        params: null
    },
    campaigns_expired_issues_fix: {
        url: 'troubleshoot/campaigns_expired_issues_fix',
        method: 'GET',
        id: null,
        params: null
    },

}